<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			@input="handleInput($event)"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";
	
	export default {
		props: {
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			},
            interval: {
                type: Number,
                default: 20
            }
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			},
		},
		data() {
			return {
				selected: null,
				options: []
			}
		},
		created() {
            this.createData()
			this.selected = this.setSelected(this.value, this.options)
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,

            createData(){

                let currentYear = new Date().getFullYear()
                let startYear = currentYear - this.interval

                for(let i = currentYear; i >= startYear; i--){
                    this.options.push({
                        value: i,
                        text: i,
                    })
                }

            }
		}
	}
</script>
